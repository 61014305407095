@media only screen and (max-width: 1440px) {
  .page {
    width: 56%;
  }
}

@media only screen and (max-width: 1200px) {
  .page {
    width: 52%;
  }

  #header-desktop .header-wrapper {
    padding-right: 1rem;
  }

  .search-dropdown.desktop {
    right: 1rem;
  }
}

@media only screen and (max-width: 960px) {
  #toc-auto {
    display: none;
  }

  #toc-static {
    display: block;
  }

  .page {
    width: 80%;
  }

  #header-desktop .header-wrapper {
    padding-left: 1rem;
  }
}

@media only screen and (max-width: 680px) {
  #header-desktop {
    display: none;
  }

  #header-mobile {
    display: block;
  }

  body.blur {
    overflow: hidden;
  }

  .page {
    width: 100%;

    [header-mobile] & {
      padding-top: $header-height;
    }

    [header-mobile=normal] & {
      padding-top: 0;
    }

    .content {
      [id] {
        [header-mobile=normal] & {
          scroll-margin-top: 0;
        }
      }
    }

    .categories-card {
      .card-item {
        width: 100%;
      }
    }
  }
  
  footer{
    .footer-container {
      font-size: .618rem;
    }
  }
}
