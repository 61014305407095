.page {
  position: relative;
  max-width: 800px;
  width: 60%;
  margin: 0 auto;

  [header-desktop] & {
    padding-top: $header-height;
  }

  [header-desktop=normal] & {
    padding-top: 0;
  }
  
  .content {
    [id] {
      scroll-margin-top: $header-height;
      
      [header-desktop=normal] & {
        scroll-margin-top: 0;
      }
    }
  }

  @include blur;
}

@import "_single";
@import "_special";
@import "_archive";
@import "_home";
@import "_404";
@import "_offline";
@import "_friends";
